import { Dialog, Popover, Tab, Transition } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"
import { Fragment, useState } from "react"
import { Link, NavLink } from "react-router-dom"

const navigation = {
  categories: [
    {
      id: "globalbrands",
      name: "Global Brands",
      featured: [
        {
          name: "Zeiss",
          href: "https://www.zeiss.com/vision-care/int/home.html",
          imageSrc:
            "https://www.zeiss.com/content/dam/Vision/Vision/International/modules/content/multislider_pl_veredelung/reinigung1.png",
          imageAlt:
            "Drawstring top with elastic loop closure and textured interior padding.",
        },
        {
          name: "Cooper Vision",
          href: "https://coopervision.com/",
          imageSrc:
            "https://coopervision.com/sites/all/modules/custom/consumer_revamp/revamp_home_page/images/homepage-2up_lower_default.png",
          imageAlt:
            "Drawstring top with elastic loop closure and textured interior padding.",
        },
        {
          name: "Irus/IDEE/Image",
          href: "https://www.idee-eyewear.com/brands/irus-by-idee.html/",
          imageSrc:
            "https://www.idee-eyewear.com/pub/media/994x386-Collection-Mobile-Aviator1.jpg",
          imageAlt:
            "Drawstring top with elastic loop closure and textured interior padding.",
        },
        {
          name: "Stepper",
          href: "https://www.steppereyewear.com/en/",
          imageSrc: "/images/stepper.jpeg",
          imageAlt:
            "Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.",
        },
        {
          name: "Menicon",
          href: "https://www.menicon.com/corporate/",
          imageSrc: "/images/menicon.jpeg",
          imageAlt:
            "Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.",
        },
        {
          name: "Rosvin Bugs",
          href: "https://www.rosvinbugs.com/",
          imageSrc: "/images/rosvinbug.jpeg",
          imageAlt:
            "Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.",
        },
      ],
    },
  ],
  pages: [
    { name: "Who We Are", href: "/about" },

    // { name: 'Career', href: '/career' },
  ],
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function Example() {
  const [open, setOpen] = useState(false)

  return (
    <div className="sticky top-0 z-50 bg-white">
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 flex lg:hidden"
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex flex-col w-full max-w-xs pb-12 overflow-y-auto bg-white shadow-xl">
              <div className="flex px-4 pt-5 pb-2">
                <button
                  type="button"
                  className="inline-flex items-center justify-center p-2 -m-2 text-gray-400 rounded-md"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>

              <div className="px-4 py-6 space-y-6 border-t border-gray-200">
                {navigation.pages.map((page) => (
                  <div key={page.name} className="flow-root">
                    <Link
                      to={page.href}
                      className="block p-2 -m-2 font-medium text-gray-900"
                    >
                      {page.name}
                    </Link>
                  </div>
                ))}
                <a
                  href="/zeisis-dristributor"
                  className="block p-2 -m-2 font-medium text-gray-900"
                  target="_blank"
                >
                  Zeiss Distributor
                </a>
              </div>

              {/* Links */}
              <Tab.Group as="div" className="mt-2">
                <div className="border-b border-gray-200">
                  <Tab.List className="flex px-4 -mb-px space-x-8">
                    {navigation.categories.map((category) => (
                      <Tab
                        key={category.name}
                        className={({ selected }) =>
                          classNames(
                            selected
                              ? "text-indigo-600 border-indigo-600"
                              : "text-gray-900 border-transparent",
                            "flex-1 whitespace-nowrap py-4 px-1 border-b-2 text-base font-medium"
                          )
                        }
                      >
                        {category.name}
                      </Tab>
                    ))}
                  </Tab.List>
                </div>
                <Tab.Panels as={Fragment}>
                  {navigation.categories.map((category) => (
                    <Tab.Panel
                      key={category.name}
                      className="px-4 pt-10 pb-8 space-y-10"
                    >
                      <div className="grid grid-cols-2 gap-4">
                        {category.featured.map((item) => (
                          <div
                            key={item.name}
                            className="relative text-sm group"
                          >
                            <div className="overflow-hidden bg-gray-100 rounded-lg aspect-w-1 aspect-h-1 group-hover:opacity-75">
                              <img
                                src={item.imageSrc}
                                alt={item.imageAlt}
                                className="object-cover object-center w-full h-20"
                              />
                            </div>
                            <a
                              href={item.href}
                              target="_blank"
                              className="block mt-6 font-medium text-gray-900"
                            >
                              <span
                                className="absolute inset-0 z-10"
                                aria-hidden="true"
                              />
                              {item.name}
                            </a>
                            <p aria-hidden="true" className="mt-1">
                              Shop now
                            </p>
                          </div>
                        ))}
                      </div>
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>

              <div className="px-4 py-6 space-y-6 border-t border-gray-200">
                <div className="flow-root">
                  <Link
                    to="/dealership"
                    className="block p-2 -m-2 font-medium text-gray-900"
                  >
                    Apply Now
                  </Link>
                </div>
                <div className="flow-root">
                  <Link
                    to="/contact"
                    className="block p-2 -m-2 font-medium text-gray-900"
                  >
                    Get in Touch
                  </Link>
                </div>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <header className="relative bg-white">
        {/* <p className="flex items-center justify-center h-10 px-4 text-sm font-medium text-white bg-blue-600 sm:px-6 lg:px-8">
          Get free delivery on orders over $100
        </p> */}

        <nav
          aria-label="Top"
          className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8"
        >
          <div className="border-b border-gray-200">
            <div className="flex items-center h-16">
              <button
                type="button"
                className="p-2 text-gray-400 bg-white rounded-md lg:hidden"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">Open menu</span>
                <MenuIcon className="w-6 h-6" aria-hidden="true" />
              </button>

              {/* Logo */}
              <div className="flex items-center justify-between w-full lg:w-auto">
                <div className="flex items-center md:ml-4 lg:ml-0">
                  <a href="/">
                    <span className="sr-only">Workflow</span>
                    <img className="w-auto h-14" src="/logo.png" alt="" />
                  </a>
                </div>
                <div className="block lg:hidden">
                  <Link
                    to="/dealership"
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm whitespace-nowrap hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Apply for Dealership
                  </Link>
                </div>
              </div>

              {/* Flyout menus */}
              <Popover.Group className="hidden lg:ml-8 lg:block lg:self-stretch">
                <div className="flex h-full space-x-3">
                  {navigation.pages.map((page) => (
                    <NavLink
                      exact={true}
                      key={page.name}
                      activeClassName="border-indigo-500 nav-hover-active"
                      to={page.href}
                      className="relative flex items-center px-3 pt-px -mb-px text-sm font-medium text-gray-700 border-transparent nav-hover-effect hover:text-gray-800"
                    >
                      {page.name}
                    </NavLink>
                  ))}

                  {navigation.categories.map((category) => (
                    <Popover key={category.name} className="flex">
                      {({ open }) => (
                        <>
                          <div className="relative flex pt-px -mb-px nav-hover-effect">
                            <Popover.Button
                              className={classNames(
                                open
                                  ? "text-indigo-600"
                                  : "border-transparent text-gray-700 hover:text-gray-800",
                                "relative z-10 flex items-center transition-colors ease-out duration-200 text-sm font-medium"
                              )}
                            >
                              {category.name}
                            </Popover.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Popover.Panel className="absolute inset-x-0 text-sm text-gray-500 top-full">
                              {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                              <div
                                className="absolute inset-0 bg-white shadow top-1/2"
                                aria-hidden="true"
                              />

                              <div className="relative bg-white">
                                <div className="px-8 mx-auto max-w-7xl">
                                  {/* <div className="grid grid-cols-2 py-16 gap-y-10 gap-x-8"> */}
                                  <div className="grid grid-cols-6 pt-5 gap-x-8">
                                    {category.featured.map((item) => (
                                      <div
                                        key={item.name}
                                        className="relative text-base group sm:text-sm"
                                      >
                                        <div className="overflow-hidden bg-gray-100 rounded-lg aspect-w-1 aspect-h-1 group-hover:opacity-75">
                                          <img
                                            src={item.imageSrc}
                                            alt={item.imageAlt}
                                            className="object-cover object-center h-40"
                                          />
                                        </div>
                                        <a
                                          href={item.href}
                                          target="_blank"
                                          className="block mt-6 mb-5 font-medium text-gray-900"
                                        >
                                          <span
                                            className="absolute inset-0 z-10"
                                            aria-hidden="true"
                                          />
                                          {item.name}
                                        </a>
                                      </div>
                                    ))}

                                    <div className="text-sm"></div>
                                  </div>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  ))}

                  <NavLink
                    exact={true}
                    activeClassName="border-indigo-500 nav-hover-active"
                    to="/zeisis-dristributor"
                    className="relative flex items-center px-3 pt-px -mb-px text-sm font-medium text-gray-700 border-transparent nav-hover-effect hover:text-gray-800"
                  >
                    Zeiss Distributor
                  </NavLink>
                </div>
              </Popover.Group>

              <div className="flex items-center h-full ml-auto">
                <div className="hidden h-full lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                  <NavLink
                    exact={true}
                    activeClassName="border-indigo-500 nav-hover-active"
                    to="/dealership"
                    className="relative flex items-center h-full px-3 pt-px -mb-px text-sm font-medium text-gray-700 border-transparent nav-hover-effect hover:text-gray-800"
                  >
                    Apply For Dealership
                  </NavLink>

                  <span className="w-px h-6 bg-gray-200" aria-hidden="true" />

                  <NavLink
                    exact={true}
                    activeClassName="border-indigo-500 nav-hover-active"
                    to="/contact"
                    className="relative flex items-center h-full px-3 pt-px -mb-px text-sm font-medium text-gray-700 border-transparent nav-hover-effect hover:text-gray-800"
                  >
                    Contact Us
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  )
}
