import './App.css';
import Nav from './components/nav';
import Footer from './components/footer';
import Home from './pages/home';
import {
  Switch,
  Route,
} from "react-router-dom";
import About from './pages/about';
import Contact from './pages/contact';
import Dealership from './pages/dealership';
import Career from './pages/career';
import Content from './pages/content';
import Brands from './pages/brands';
import PrivacyPolicy from './pages/privacyPolicy';
import Partners from './pages/partners';
import ZeissDristributor from './pages/zeissDristributor';

function App() { 
  return (
    <>
      <Nav />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/dealership" component={Dealership} />
          <Route exact path="/career" component={Career} />
          <Route exact path="/brands" component={Brands} />
          <Route exact path="/content" component={Content} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/partners" component={Partners} />
          <Route exact path="/zeisis-dristributor" component={ZeissDristributor} />

        </Switch>
      <Footer />
     

    </>
  );
}

export default App;
