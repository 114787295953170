import React, { useEffect } from 'react'
// import Hero from '../visioncare/hero'
import Dealers from '../visioncare/dealers'
// import Promotion from '../components/promo'
import Whyus from '../visioncare/whyus'

function Home() {
    useEffect(() => {
        //scroll to top
        window.scrollTo(0, 0);
    },[])

    return (
        <>
            {/* <Hero /> */}
            <Whyus />
            <Dealers />
            {/* <Promotion /> */}
        </>
    )
}

export default Home