export const logoList = [
  {
    href: 'https://www.zeiss.com',
    src: "https://www.zeiss.com/docroot/zeiss-corporate/cp3/clientlibmain/4ef535211b9b4fc5fc27c7562a9eb597.svg",
    alt: "Logo of zeiss",
    title: "Zeiss"
  },
  {
    href: 'https://coopervision.com/',
    src: "/images/coopervision.jpg",
    alt: "Logo of Cooper Vision",
    title: "Cooper Vision"
  },
  {
    href: 'https://www.rosvinbugs.com/',
    src: "/images/rosvinbug.jpg",
    alt: "Logo of Rosvinbug",
    title: "Rosvinbug"
  },
  {
    href: 'http://image-eyewear.com/',
    src: "/images/image.jpg",
    alt: "Logo of ImageEyeware",
    title: "Image Eyeware"
  },
  {
    href: 'https://www.idee-eyewear.com/brands/irus-by-idee.html',
    src: "/images/IRUS.jpg",
    alt: "Logo of IRUS",
    title: "Idee Eye Wear"
  },
]