import React, { useEffect, useState } from 'react'

const people = [
  {
    name: "SUDRISHTI EYE Clinic",
    address: "Durbarmarg",
    phone: 9851310931,
    email: null
  },
  {
    name: "KEC OPTICALS",
    address: "Lagankhel",
    phone: 9861768991,
    email: null
  },
  {
    name: "NETRADHAM EYE CARE CENTER PVT LTD",
    address: "kupondol",
    phone: 9866648828,
    email: null
  },
  {
    name: "SUMERU OPTICALS ",
    address: "Jawalakhel",
    phone: 9851086121,
    email: null
  },
  {
    name: "SONIYA OPTICALS",
    address: "Tripureshwor",
    phone: 9860304767,
    email: null
  },
  {
    name: "I CARE CLINIC",
    address: "Naxal",
    phone: 9849044897,
    email: null
  },
  {
    name: "SUDRISHTI OPTICALS PVT.LTD ",
    address: "Lazimpat",
    phone: 14253247,
    email: null
  },
  {
    name: "PADMA OPTICALS ",
    address: "Maitidevi",
    phone: 9856030126,
    email: null
  },
  {
    name: "VISION CONCERN PVT. LTD.",
    address: "Baudhha\/Lazimpat\/Makalbari",
    phone: 9851061108,
    email: "visionconcern@gmail.com"
  },
  {
    name: "LOOKSKART",
    address: "Durbarmarg\/Charkhal\/Bishalbazar",
    phone: 9851206002,
    email: null
  },
  {
    name: "NEW OPTICS",
    address: "Naxal",
    phone: 9855056052,
    email: null
  },
  {
    name: "KANTIPUR CHASMA MARMAT TATHA BIKRI PASAL",
    address: "Tripureshwor",
    phone: 9841265822,
    email: null
  },
  {
    name: "KEC",
    address: "Jwalakhel",
    phone: 9857035765,
    email: null
  },
  {
    name: "METRO EYE CARE ",
    address: "Tinkune ",
    phone: 9851037234,
    email: null
  },
  {
    name: "CARE EYE GROUP",
    address: "Maitidevi",
    phone: 9851238548,
    email: null
  },
  {
    name: "EYE DEAL",
    address: "Jwalakhel",
    phone: 9841957812,
    email: null
  },
  {
    name: "B & B OPTICAL EYE CLINIC ",
    address: "Lagankhel",
    phone: 9851238548,
    email: null
  },
  {
    name: "SAMAJ OPTICAL",
    address: "Baneshwor ",
    phone: 9851061545,
    email: null
  },
  {
    name: "EVEREST OPTICALS",
    address: "Tripureshwor ",
    phone: 9851008594,
    email: null
  },
  {
    name: "CHANDESWORI OPTICAL HOUSE ",
    address: "Maharajgunj ",
    phone: 9841537758,
    email: null
  },
  {
    name: "OPTIC PRODUCT DISTRIBUTOR",
    address: "Maitiidevi ",
    phone: "01-4434870",
    email: null
  },
  {
    name: "LONDON OPTICIAN",
    address: "Tripureshwor ",
    phone: 9840269768,
    email: null
  },
  {
    name: "OPTIK HUT",
    address: "Chetrapati ",
    phone: 9801011523,
    email: null
  },
  {
    name: "MIKHA EYE WEAR",
    address: "Durbarmarg",
    phone: "01-4224378",
    email: null
  },
  {
    name: "ILLAMELI CHASMA GHAR",
    address: "Shantinagar ",
    phone: 9841698032,
    email: null
  },
  {
    name: "Perfect Vision ",
    address: "Thimi ",
    phone: 9849807812,
    email: null
  },
  {
    name: "Eye Care Optical-Maharajgunj ",
    address: "Maharajgunj ",
    phone: 9851114555,
    email: null
  },
  {
    name: "BHAROSA OPTICALS",
    address: "Old Baneshwor",
    phone: 9840099999,
    email: null
  },
  {
    name: "DRISHTI THE VISION",
    address: "Kantipath ",
    phone: 9851084789,
    email: null
  },
  {
    name: "NEWMEW",
    address: "Kathmandu",
    phone: 9851200990,
    email: "Newmew@gmail.com "
  },
  {
    name: "BUDDHA OPTICAL",
    address: "Shantinagar",
    phone: 9841385785,
    email: null
  },
  {
    name: "BEST DEAL",
    address: "Jwalakhel ",
    phone: 9807869804,
    email: null
  },
  {
    name: "BETTER VISION",
    address: "Jwalakhel ",
    phone: 9841219809,
    email: null
  },
  {
    name: "OPTIC GALLERY ",
    address: "Shantinagar",
    phone: 9841840864,
    email: null
  },
  {
    name: "AJIMA OPTICIAN",
    address: "Hatiban",
    phone: 9849809820,
    email: null
  },
  {
    name: "GLASSES OPTICAL",
    address: "Chetrapatti",
    phone: 9803904909,
    email: null
  },
  {
    name: "Suncity Eye care center",
    address: "Pepsicola",
    phone: 9849807812,
    email: null
  },
  {
    name: "Clear Vision Optical",
    address: "Itahari",
    phone: 9852055394,
    email: null
  },
  {
    name: "Clear Vision Pokhara",
    address: "Pokhara",
    phone: 9856028560,
    email: null
  },
  {
    name: "Pathivara Eye Clinic and Optical ",
    address: "Damak",
    phone: 9852683086,
    email: null
  },
  {
    name: "Birtamode Eye Hospital",
    address: "Birtamode",
    phone: 9852678993,
    email: null
  },
  {
    name: "DRISHTI EYE CARE SYSTEM",
    address: "Kathmandu\/Birtamode\/Birgunj",
    phone: "01-4783994",
    email: null
  },
  {
    name: "TRINETRA OPTICAL",
    address: "POKHARA",
    phone: 9856064690,
    email: null
  },
  {
    name: "PADMA OPTICAL POKHARA ",
    address: "POKHARA",
    phone: 9856030908,
    email: null
  },
  {
    name: "GLOAL EYE HOSPITAL",
    address: "ITAHARI\/KATHMANDU",
    phone: 9851054783,
    email: null
  },
  {
    name: "NATIONAL CHASMA GAHR ",
    address: "BIRATNAGAR",
    phone: 9842020786,
    email: null
  },
  {
    name: "BIBEK CHASMA GHAR ",
    address: "BIRATNAGAR",
    phone: 9852030635,
    email: null
  },
  {
    name: "ANAMIKA CHASMA GHAR ",
    address: "BIRATNAGAR",
    phone: 9842553067,
    email: null
  },
  {
    name: "MECHI EYE HOSPITAL",
    address: "BIRTAMODE",
    phone: 9845239974,
    email: null
  },
  {
    name: "MECHI DRISHTI EYE HOSPITAL",
    address: "BIRTAMODE",
    phone: 9815963579,
    email: null
  },
  {
    name: "BIRGUNJ DRISHTI EYE HOSPITAL",
    address: "BIRGUNJ",
    phone: 9801566588,
    email: null
  },
  {
    name: "METRO OPTICAL",
    address: "NEW BANESHWOR ",
    phone: 9841493414,
    email: null
  },
  {
    name: "MATRIKA EYE HOSPITAL",
    address: "OLD BANESHWOR ",
    phone: 9843170653,
    email: null
  },
  {
    name: "CHABAHIL DRISHTI EYE CARE CENTER",
    address: "CHABAHIL",
    phone: 9818666447,
    email: null
  },
  {
    name: "BISHROOM ",
    address: "KATHMANDU",
    phone: 9841501035,
    email: null
  },
  {
    name: "NEW OPTICS, CHITWAN",
    address: "CHITWAN",
    phone: 9855056052,
    email: null
  },
  {
    name: "K SEE OPTICAL ",
    address: "BUTWAL",
    phone: 9857035765,
    email: null
  },
  {
    name: "HIMALAYAN EYE HOSPITAL",
    address: "POKHRA",
    phone: 9844187550,
    email: null
  },
  {
    name: "TILGANGA EYE HOSPITAL",
    address: "KATHMANDU",
    phone: 9851050245,
    email: null
  },
  {
    name: "NAVA DRISHTI EYE HOSPITAL ",
    address: "ITAHARI",
    phone: 9849445566,
    email: null
  },
  {
    name: "METRO CITY OPTICAL",
    address: "MAITIDEVI",
    phone: 9860805547,
    email: null
  },
  {
    name: "SRI KRISHNA NETRALAYA ",
    address: "BHAIRAWA",
    phone: 9826426365,
    email: null
  },
  {
    name: "PRATULIKA OPTICAL",
    address: "BHAIRAWA",
    phone: 9863292862,
    email: null
  },
  {
    name: "KIRAN EYE HOSPITAL ",
    address: "BIRGUNJ",
    phone: 9811281633,
    email: null
  },
  {
    name: "BIRGUNJ ANKHA TATHA DAATH ASPATAAL ",
    address: "BIRGUNJ",
    phone: 9802918784,
    email: null
  }
]

function ZeissDristributor() {
  const [items, setItems] = useState(people)

  function handleSearch(search) {
    const data = people.filter((item) => {
      let name = item.name.toLocaleLowerCase();
      let address = item.address.toLocaleLowerCase();
      let searchName = search.toLocaleLowerCase();
      let searchAddress = search.toLocaleLowerCase();
      if (name.includes(searchName) || address.includes(searchAddress)) {
        return item;
      }
      return 0;
    });
    setItems(data);
  }

  useEffect(() => {
    //scroll to top
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className="bg-gray-100">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl mb-10 text-center">Zeiss Distributor</h2>
        <div className='max-w-md mx-auto mb-20'>
          <div className="mt-1 relative flex items-center">
            <input
              type="text" name="search" id="search" placeholder='Search' onChange={(e) => handleSearch(e.target.value)}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {items && items.length ? items.map((person, index) => (
            <div
              key={person.phone + index}
              className="relative rounded-lg border border-gray-300 bg-white px-6
            py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400
            focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
              {/* <div className="flex-shrink-0">
                <img className="h-10 w-10 rounded-full" src={person.imageUrl} alt="" />
            </div> */}
              <div className="flex-1 min-w-0 pl-2">
                <div className="focus:outline-none text-left">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-md mb-1">
                    <span className='font-medium text-gray-900'>Company Name: </span>
                    {person.name}
                  </p>
                  <p className="text-md truncate mb-1">
                    <span className='font-medium text-gray-900'>Phone: </span>
                    {person.phone}
                  </p>
                  {person.email && <p className="text-smdm truncate mb-1">
                    <span className='font-medium text-gray-900'>Email: </span>
                    {person.email}
                  </p>}
                  <p className="text-md truncate mb-1">
                    <span className='font-medium text-gray-900'>Address: </span>
                    {person.address}
                  </p>
                </div>
              </div>
            </div>
            // <li key={person.email} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
            //   <div className="w-full flex items-center justify-between p-6 space-x-6">
            //     <div className="flex-1 truncate">
            //       <div className="flex items-center space-x-3">
            //         <h3 className="text-gray-900 text-sm font-medium truncate">{person.name}</h3>
            //         {/* <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
            //           {person.role}
            //         </span> */}
            //       </div>
            //       <p className="mt-1 text-gray-500 text-sm truncate">{person.address}</p>
            //     </div>
            //     <img className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0" src={person.imageUrl} alt="" />
            //   </div>
            //   <div>
            //     <div className="-mt-px flex divide-x divide-gray-200">
            //       <div className="w-0 flex-1 flex">
            //         <a
            //           href={`mailto:${person.email}`}
            //           className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
            //         >
            //           <MailIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
            //           <span className="ml-3">Email</span>
            //         </a>
            //       </div>
            //       <div className="-ml-px w-0 flex-1 flex">
            //         <a
            //           href={`tel:${person.telephone}`}
            //           className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
            //         >
            //           <PhoneIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
            //           <span className="ml-3">Call</span>
            //         </a>
            //       </div>
            //     </div>
            //   </div>
            // </li>
          )) :
            <div className='sm:col-span-2 lg:col-span-3'>
              <h1 className='text-center text-2xl'>Not Available!</h1>
            </div>
          }
        </ul>
      </div>
    </div>
  )
}

export default ZeissDristributor;