import React, { useState } from 'react'

function Partners() {
    const partners = [
        {
            name: 'Leslie Alexander',
            email: 'abc@gmail.com',
            address: 'Downing Street, London',
            phone: 'Co-Founder / CEO',
            imageUrl:
                'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            name: 'John Doe',
            email: 'doe@gmail.com',
            address: 'Sility Street, London',
            phone: 'Co-Founder / CEO',
            imageUrl:
                'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            name: 'Ram Kumar',
            email: 'ai@gmail.com',
            address: 'Window Street, London',
            phone: 'Co-Founder / CEO',
            imageUrl:
                'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            name: 'Samuel Anderson',
            email: 'db@gmail.com',
            address: 'Downing Street, London',
            phone: 'Co-Founder / CEO',
            imageUrl:
                'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    ]

    const [items, setItems] = useState(partners)

    function handleSearch(search) {
        const data = partners.filter((item) => {
            let name = item.name.toLocaleLowerCase();
            let searchText = search.toLocaleLowerCase();
            if (name.includes(searchText)) {
                return item;
            }
            return 0;
        });
        setItems(data);
    }

    return (
        <div className="bg-gray-100">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl mb-10 text-center">Our Partners</h2>
                <div className='max-w-md mx-auto mb-20'>
                    <div className="mt-1 relative flex items-center">
                        <input
                            type="text" name="search" id="search" placeholder='Search' onChange={(e) => handleSearch(e.target.value)}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                        />
                    </div>
                </div>

                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                    {items && items.length ? items.map((person) => (
                        <div
                            key={person.email}
                            className="relative rounded-lg border border-gray-300 bg-white px-6 
                            py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 
                            focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                            {/* <div className="flex-shrink-0">
                                <img className="h-10 w-10 rounded-full" src={person.imageUrl} alt="" />
                            </div> */}
                            <div className="flex-1 min-w-0 pl-2">
                                <button className="focus:outline-none text-left">
                                    <span className="absolute inset-0" aria-hidden="true" />
                                    <p className="text-md mb-1">
                                        <span className='font-medium text-gray-900'>Company Name:</span> {person.name}</p>
                                    <p className="text-md truncate mb-1">
                                        <span className='font-medium text-gray-900'>Phone:</span> {person.phone}</p>

                                    <p className="text-smdm truncate  mb-1">
                                        <span className='font-medium text-gray-900'>Email:</span> {person.email}</p>
                                    <p className="text-md truncate  mb-1">
                                        <span className='font-medium text-gray-900'>Address:</span> {person.address}</p>
                                </button>
                            </div>
                        </div>
                    )) :
                        <div className='sm:col-span-2 lg:col-span-3'>
                            <h1 className='text-center text-2xl'>Not Available!</h1>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Partners