import React from 'react'
import { ToastContainer } from 'react-toastify';
import CareerForm from '../components/Forms/CareerForm';

function Career() {

    return (
        <div className="relative bg-white">
            <div className="lg:absolute lg:inset-0">
                <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                    <img
                        className="object-cover w-full h-56 lg:absolute lg:h-full"
                        src="https://images.unsplash.com/photo-1556761175-4b46a572b786?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80"
                        alt=""
                    />
                </div>
            </div>
            <div className="relative px-4 py-16 sm:py-24 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:py-32 lg:grid lg:grid-cols-2">
                <div className="lg:pr-8">
                    <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
                        <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Let's work together</h2>
                        <p className="mt-4 text-lg text-gray-500 sm:mt-3">
                            We’d love to hear from you! Send us a message using the form opposite, or email us. We’d love to hear from
                            you! Send us a message using the form opposite, or email us.
                        </p>
                        <CareerForm />
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>

    )
}

export default Career