import axios from "axios";

export const postContact = async (contact) => {
    const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/contact`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(contact)
    });

    // if (!res.ok) {
    //     const errorRes = await res.json()
    //     const error = new Error(errorRes.error)
    //     error.message = errorRes.message
    //     throw error
    // }

    return res.json();
}

export const postDealership = async (dealership) => {
    const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/dealership`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(dealership)
    });
    return res.json();
}

export const postCareer = async (career) => {
    console.log(career);
    const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/career`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(career)
    });

    if (!res.ok) {
        const errorRes = await res.json()
        const error = new Error(errorRes.error)
        error.message = errorRes.message
        throw error
    }

    return res.json();
}

export const getCaptcha = async (token) => {
    const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/captcha`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(token)
    });

    return res.json();
}
