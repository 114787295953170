import React, { useEffect } from "react"
import Logos from "../components/logos"

function About() {
  useEffect(() => {
    document.title = "VisionCare | About"

    //scroll to top
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-indigo-600 tracking-wide uppercase">
            About Us
          </h2>
          <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            About Us
          </p>
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
            An Exclusive National Authorized Distributor for Premium Optical
            Products
          </p>
        </div>
      </div>

      <div className="relative bg-white">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
            <img
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1567&q=80"
              alt=""
            />
          </div>
        </div>
        <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:col-start-2 lg:pl-8">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
              <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
                Work with us
              </h2>
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Why High Gaze Optics
              </h3>
              <p className="mt-8 text-lg text-gray-500">
                This is a trusted company for Opticals retails and hospital
                retails to buy different products.The team of High Gaze Optics
                provides a comprehensive solution to enhance the performance of
                any opticals in terms of sales and quality of service. The
                company provides frequent door to door training ,mass training
                for different products free of cost(so far) which boost the
                confidence of the counter salesperson.
              </p>
              <div className="mt-8 text-lg text-gray-500">
                <p>
                  The delivery system of the distribution network of the company
                  is also strong enough to make the delivery of most of the
                  products in the given time frame.
                </p>
                {/* <h4 className='font-bold my-4'>
                                    Associated Brands
                                </h4>

                                <ul role="list">
                                    <li>Zeiss</li>
                                    <li>Cooper Vision</li>
                                    <li>Menicon</li>
                                    <li>Irus/IDEE/Image</li>
                                    <li>Rosvin Bugs</li>
                                    <li>Stepper</li>
                                    <li>Stepper</li>

                                </ul>
                                <p>
                                    Rhoncus nisl, libero egestas diam fermentum dui. At quis tincidunt vel ultricies. Vulputate aliquet
                                    velit faucibus semper. Pellentesque in venenatis vestibulum consectetur nibh id. In id ut tempus
                                    egestas. Enim sit aliquam nec, a. Morbi enim fermentum lacus in. Viverra.
                                </p>
                                <h3>How we’re different</h3>
                                <p>
                                    Tincidunt integer commodo, cursus etiam aliquam neque, et. Consectetur pretium in volutpat, diam.
                                    Montes, magna cursus nulla feugiat dignissim id lobortis amet. Laoreet sem est phasellus eu proin massa,
                                    lectus. Diam rutrum posuere donec ultricies non morbi. Mi a platea auctor mi.
                                </p>
                                <p>
                                    Mauris ullamcorper imperdiet nec egestas mi quis quam ante vulputate. Vel faucibus adipiscing lacus,
                                    eget. Nunc fermentum id tellus donec. Ut metus odio sit sit varius non nunc orci. Eu, mi neque, ornare
                                    suspendisse amet, nibh. Facilisi volutpat lectus id sapien dis mauris rhoncus. Est rhoncus, interdum
                                    imperdiet ac eros, diam mauris, tortor. Risus id sit molestie magna.
                                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Logos />
    </div>
  )
}

export default About
