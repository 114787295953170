import React from 'react'
import { logoList } from "../utils/data";
import Slider from "react-slick";

function Logos() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className="bg-white">
            <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="px-3 mt-8">

                    <Slider {...settings}>
                        {logoList.map((logo) => (
                            <div key={logo.href}>
                                <a href={logo.href} title={logo.title} target="_blank">
                                    <div className="flex justify-center p-4 overflow-hidden border md:p-8 group">
                                        <img className="min-w-[3rem] max-h-20 object-contain group-hover:scale-125 ease-in-out duration-500" src={logo.src} alt={logo.alt} title={logo.title} />
                                    </div>
                                </a>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>

    )
}

export default Logos