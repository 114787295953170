import { DeviceMobileIcon, MailIcon, PhoneIcon } from "@heroicons/react/outline"
import Cookies from "js-cookie"
import { useEffect, useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import * as yup from "yup"
import ErrorMessage from "../components/ErrorMessage"
import { getCaptcha, postContact } from "../data/api.service"

const { yupResolver } = require("@hookform/resolvers/yup")

export default function Contact() {
  const reRef = useRef()

  const [submitting, setSubmitting] = useState(false)
  const notify = () => toast("Form submitted successfully! ")
  const error = (message) =>
    toast.error(message ? message : "Something Went Wrong ! ")
  const captcha = () => toast.error("You must complete captcha ! ")

  const onSubmit = async (data) => {
    setSubmitting(true)
    const token = await reRef.current.getValue()
    if (!token) {
      captcha()
      setSubmitting(false)
      return
    }

    const formData = new FormData()
    formData.append("name", data.name)
    formData.append("phone", data.phone)
    formData.append("email", data.email)
    formData.append("message", data.message)
    formData.append("_csrf", Cookies.get("csrf-token"))

    const res = getCaptcha({ token })

    res.then((res) => {
      if (res.success) {
        const response = postContact({ formData })
        response
          .then(() => {
            if (response.data.success) {
              notify()
              setSubmitting(false)
              reRef.current.reset()
            } else {
              error()
              setSubmitting(false)
              reRef.current.reset()
            }
          })
          .catch((err) => {
            console.log(err)
            error()
            setSubmitting(false)
            reRef.current.reset()
          })
      }
    })
    // .catch((err) => {
    //     console.log(err);
    //     captcha();
    //     error();
    //     setSubmitting(false);
    //     reRef.current.reset();
    // });
  }

  useEffect(() => {
    //scroll to top
    window.scrollTo(0, 0)
  }, [])

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  let schema = yup.object().shape({
    name: yup.string().required("This field is required."),
    phone: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("This field is required."),
    email: yup.string().email().required("This field is required."),
    message: yup
      .string()
      .min(10, "Your message is too short")
      .required("This field is required."),
  })

  yupResolver(schema)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  return (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
        <div className="px-4 py-16 bg-gray-50 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              Get in touch
            </h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              A comprehensive solution to enhance the performance of any
              opticals in terms of sales and quality of service
            </p>
            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="sr-only">Address</dt>
                <dd>
                  <p class="font-semibold">High Gaze Optics Pvt Ltd</p>
                  <p class="font-semibold">World Trade Center, Tripureshwor</p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <PhoneIcon
                    className="flex-shrink-0 w-6 h-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">01-4117046, 01-5920088</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Mobile number</dt>
                <dd className="flex">
                  <DeviceMobileIcon
                    className="flex-shrink-0 w-6 h-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">+977 980262244, +977 9802625999</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <MailIcon
                    className="flex-shrink-0 w-6 h-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">visioncaredivision@gmail.com</span>
                </dd>
              </div>
            </dl>
            <p className="mt-6 text-base text-gray-500">
              Looking for careers?{" "}
              <Link
                to="/career"
                className="font-medium text-gray-700 underline"
              >
                View all job openings
              </Link>
              .
            </p>
          </div>
        </div>
        <div className="px-4 py-16 bg-white sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <h2 class="text-2xl text-gray-800 font-bold">
              We are starting this service soon..
            </h2>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="grid grid-cols-1 mt-8 gap-y-6"
            >
              <div>
                <label htmlFor="full-name" className="sr-only">
                  Full name
                </label>
                <input
                  disabled={true}
                  type="text"
                  name="name"
                  id="full-name"
                  autoComplete="name"
                  className="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Full name"
                  {...register("name")}
                />
                {errors.name && <ErrorMessage message={errors.name.message} />}
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  disabled={true}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Email"
                  {...register("email")}
                />
                {errors.email && (
                  <ErrorMessage message={errors.email.message} />
                )}
              </div>
              <div>
                <label htmlFor="phone" className="sr-only">
                  Phone
                </label>
                <input
                  disabled={true}
                  type="text"
                  name="phone"
                  id="phone"
                  autoComplete="tel"
                  className="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Phone"
                  {...register("phone")}
                />
                {errors.phone && (
                  <ErrorMessage message={errors.phone.message} />
                )}
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  Message
                </label>
                <textarea
                  disabled={true}
                  id="message"
                  name="message"
                  rows={4}
                  className="block w-full px-4 py-3 placeholder-gray-500 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Message"
                  defaultValue={""}
                  {...register("message")}
                />
                {errors.message && (
                  <ErrorMessage message={errors.message.message} />
                )}
              </div>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                ref={reRef}
              />
              <div>
                <button
                  // disabled={submitting}
                  disabled={true}
                  type="submit"
                  className="inline-flex justify-center px-6 py-3 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {submitting ? "Sending..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}
