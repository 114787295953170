/* This example requires Tailwind CSS v2.0+ */
import { NewspaperIcon, PhoneIcon, SupportIcon } from "@heroicons/react/outline"
import { Link } from "react-router-dom"

const supportLinks = [
  {
    name: "Training for optical employees",
    href: "/contact",
    description:
      "The company provides frequent door to door training ,mass training for different products free of cost(so far) which boost the confidence of the counter salesperson.",
    icon: PhoneIcon,
  },
  {
    name: "Digital Marketing Support",
    href: "/contact",
    description:
      "High Gaze Optics also provides a digital marketing support for the opticals in order to promote their business and increase sale and brand awareness.",
    icon: SupportIcon,
  },
  {
    name: "Branding Support",
    href: "/contact",
    description:
      "High Gaze Optics helps the optical and business owners to create a brand consistency while advertising or promoting their business to the market.",
    icon: NewspaperIcon,
  },
]

export default function Whyus() {
  return (
    <div className="bg-white">
      {/* Header */}
      <div className="relative pb-32 bg-gray-800">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100"
            alt=""
          />
          <div
            className="absolute inset-0 bg-gray-800 mix-blend-multiply"
            aria-hidden="true"
          />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-28 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-5xl">
            Why High Gaze Optics
          </h1>
          <p className="mt-6 max-w-3xl text-lg text-gray-300">
            High Gaze Optics was founded with the goal to provide branded
            optical products like Frames, Lenses and Contact lenses to the
            Optical and hospital retailers across the country. Global brands
            like Zeiss, CooperVision, and Menicon have trusted us to be the
            national sole distributor for Nepal. Our company provides frequent
            product training to the opticals and sales training to boost the
            confidence of the salesperson of the optical.
          </p>
        </div>
      </div>

      {/* Overlapping cards */}
      <section
        className="-mt-32 max-w-7xl mx-auto relative z-10 pb-8 px-4 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <h2 className="sr-only" id="contact-heading">
          Contact us
        </h2>
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
          {supportLinks.map((link) => (
            <div
              key={link.name}
              className="flex flex-col bg-white rounded-2xl shadow-xl"
            >
              <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                <div className="absolute top-0 p-5 inline-block bg-blue-600 rounded-xl shadow-lg transform -translate-y-1/2">
                  <link.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <h3 className="text-xl font-medium text-gray-900">
                  {link.name}
                </h3>
                <p className="mt-4 text-base text-gray-500">
                  {link.description}
                </p>
              </div>
              <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                <Link
                  to={link.href}
                  className="text-base font-medium text-blue-700 hover:text-blue-600"
                >
                  Contact us<span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  )
}
