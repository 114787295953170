import {logoList} from "../utils/data";

export default function Dealers() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        {/* <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center"> */}
        <div className="flex flex-col md:flex-row gap-x-16 lg:items-center">
          <div className="max-w-md">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Trusted & Authorized Distributor of Global Brands
            </h2>
            <p className="mt-3 max-w-3xl text-lg text-gray-500">
            The distribution network of the company is strong enough to make the product delivered in the given time frame to most of the cities of Nepal.</p>
            <div className="mt-8 sm:flex">
              <div className="rounded-md shadow">
                <a
                  href="/dealership"
                  className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-indigo-700"
                >
                  Apply Now for Dealership
                </a>
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <a
                  href="/contact"
                  className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-indigo-200"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div> 
          
          <div className="mt-8 grid grid-cols-2 sm:grid-cols-3 gap-1 md:grid-cols-3 lg:mt-0 lg:grid-cols-4">
            
            {logoList.map((logo) => (
              <a key={logo.href} href={logo.href} title={logo.title}>
                <div className="col-span-1 flex justify-center p-4 md:p-8 border overflow-hidden group">
                  <img className="min-w-[3rem] max-h-20 object-contain group-hover:scale-125 ease-in-out duration-500" src={logo.src} alt={logo.alt} />
                </div>
              </a>
            ))}

          </div>
        </div>
      </div>
    </div>
  )
}
