import { useEffect, useRef, useState } from 'react'
import { Switch } from '@headlessui/react'
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import ErrorMessage from '../components/ErrorMessage'
import { ToastContainer, toast } from 'react-toastify'
import * as yup from "yup"
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import { getCaptcha, postDealership } from '../data/api.service';
import Cookies from 'js-cookie';
const { yupResolver } = require("@hookform/resolvers/yup")

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Dealership() {
    const reRef = useRef();

    const [agreed, setAgreed] = useState(false)
    const [submitting, setSubmitting] = useState(false);

    const notify = () => toast("Form submitted successfully! ");
    const error = () => toast.error("Something Went Wrong ! ");
    const captchaError = () => toast.error("You must complete captcha ! ");

    const onSubmit = async (data) => {
        console.log(data);
        setSubmitting(true);
        const token = await reRef.current.getValue();
        if(!token) {
            captchaError();
            setSubmitting(false);
            return;
        }

        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("hospitalName", data.hospitalName);
        formData.append("applyFor", data.applyFor);
        formData.append("phone", data.phone);
        formData.append("email", data.email);
        formData.append("location", data.location);
        formData.append("privacy", data.privacy);
        formData.append("_csrf", Cookies.get('csrf-token'));

        const response = getCaptcha({token});

        response.then((res) => {
            console.log(res);
            if (res.success) {
                const response = postDealership(formData);
                response.then(() => {
                    if (response.data.success) {
                        notify();
                        setSubmitting(false);
                        reRef.current.reset();
                    } else {
                        error();
                        setSubmitting(false);
                        reRef.current.reset();
                    }
                }).catch((err) => {
                    console.log(err);
                    error();
                    setSubmitting(false);
                    reRef.current.reset();
                });
            }
        })
        // .catch((err) => {
        //     console.log(err);
        //     captchaError();
        //     setSubmitting(false);
        //     reRef.current.reset();
        // });

        // axios.post(`${process.env.REACT_APP_API_ENDPOINT}/captcha`, {
        //     token: token
        // }, {
        //     headers: {
        //         'XSRF-Token': Cookies.get('XSRF_TOKEN'),
        //     }
        // }).then(res => {
        //     if (res.data.success) {
        //         axios.post(`${process.env.REACT_APP_API_ENDPOINT}/dealership`, data , {
        //             headers: {
        //                 'XSRF-Token': Cookies.get('XSRF_TOKEN'),
        //             }
        //         })
        //             .then((res) => {
        //                 if (res.data.success) {
        //                     notify();
        //                     setSubmitting(false);
        //                     reRef.current.reset();

        //                 } else {
        //                     error();
        //                     setSubmitting(false);
        //                     reRef.current.reset();
        //                 }
        //             })
        //     } else {
        //         error();
        //         setSubmitting(false);
        //         reRef.current.reset();
        //     }
        // }).catch(err => {
        //     console.log(err);
        //     error();
        //     setSubmitting(false);
        //     reRef.current.reset();
        // });

        
    };

    useEffect(() => {
        //scroll to top
        window.scrollTo(0, 0);
    },[])

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    let schema = yup.object().shape({
        name: yup.string().required("This field is required."),
        hospitalName: yup.string().required("This field is required."),
        applyFor: yup.array().min(1, "Please select what you are applying for.").required(),
        phone: yup.string().matches(phoneRegExp, 'Phone number is not valid.').required("This field is required."),
        email: yup.string().email().required("This field is required."),
        location: yup.string().min(10, "Your location description is too short.").required("This field is required."),
        privacy: yup.boolean().oneOf([true], "You must agree to our privacy policy.").required("This field is required."),
    });

    yupResolver(schema)
    const { register, handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const applyForOptions = [
        { value: 'Zeiss', label: 'Zeiss' },
        { value: 'Cooper Vision', label: 'Cooper Vision' },
        { value: 'Stepper', label: 'Stepper' },
        { value: 'Menicon', label: 'Menicon' },
        { value: 'Irus/IDEE/Image', label: 'Irus/IDEE/Image' },
        { value: 'Rosvin Bugs', label: 'Rosvin Bugs' },
    ]

    return (
        <div className="px-4 py-16 overflow-hidden bg-white sm:px-6 lg:px-8 lg:py-24">
            <div className="relative max-w-xl mx-auto">
                {/* Backgrund Pattern*/}
                <svg
                    className="absolute transform translate-x-1/2 left-full"
                    width={404} height={404} fill="none" viewBox="0 0 404 404" aria-hidden="true"
                >
                    <defs>
                        <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
                </svg>
                <svg
                    className="absolute bottom-0 transform -translate-x-1/2 right-full"
                    width={404}
                    height={404}
                    fill="none"
                    viewBox="0 0 404 404"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="85737c0e-0916-41d7-917f-596dc7edfa27"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
                </svg>
                <div className="text-center">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Apply for Dealership</h2>
                </div>
                <div className="mt-12">
                    <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                        <div className='col-span-2'>
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Name of the owner/Representative
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="name"
                                    id="first-name"
                                    autoComplete="given-name"
                                    className="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                    {...register("name")}
                                />
                            </div>
                            {errors.name && <ErrorMessage message={errors.name.message} />}

                        </div>

                        <div className="col-span-2">
                            <label htmlFor="hospitalName" className="block text-sm font-medium text-gray-700">
                                Optical/Hospital name
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="hospitalName"
                                    id="company"
                                    autoComplete="organization"
                                    className="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                    {...register("hospitalName")}
                                />
                            </div>
                            {errors.hospitalName && <ErrorMessage message={errors.hospitalName.message} />}

                        </div>

                        <div className="col-span-2">
                            <label htmlFor="applyFor" className="block mb-1 text-sm font-medium text-gray-700">
                                Apply for
                            </label>


                            <Controller
                                control={control}
                                defaultValue={[]}
                                name="applyFor"
                                render={({ field: { onChange, value, ref } }) => (
                                    <Select
                                        inputRef={ref}
                                        value={applyForOptions.find((c) => c.value === value)}
                                        onChange={(val) => onChange(val.map((c) => c.value))}
                                        options={applyForOptions}
                                        isMulti
                                        styles={{
                                            input: (base) => ({
                                                ...base,
                                                'input:focus': {
                                                    boxShadow: 'none',
                                                },
                                            }),
                                        }}

                                    />
                                )}
                            />

                            {errors.applyFor && <ErrorMessage message={errors.applyFor.message} />}

                        </div>
                        <div className="col-span-2">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email
                            </label>
                            <div className="mt-1">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    className="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                    {...register("email")}
                                />
                                {errors.email && <ErrorMessage message={errors.email.message} />}
                            </div>
                        </div>
                        <div className="col-span-2">
                            <label htmlFor="phone-number" className="block text-sm font-medium text-gray-700">
                                Phone Number
                            </label>
                            <div className="relative mt-1 rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 flex items-center">
                                    <label htmlFor="country" className="sr-only">
                                        Country
                                    </label>
                                </div>
                                <input
                                    className="block w-full px-4 py-3 pl-3 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                                    type="text" name="phone" id="phone-number" autoComplete="tel"
                                    placeholder="+1 (555) 987-6543"
                                    {...register("phone")}
                                />
                                {errors.phone && <ErrorMessage message={errors.phone.message} />}
                            </div>
                        </div>
                        <div className="col-span-2">
                            <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                                Location
                            </label>
                            <div className="mt-1">
                                <textarea
                                    id="location"
                                    name="location"
                                    rows={4}
                                    className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                    defaultValue={''}
                                    {...register("location")}

                                />
                                {errors.location && <ErrorMessage message={errors.location.message} />}
                            </div>
                        </div>
                        <div className="col-span-2">
                            <div className="flex items-start">
                                <div className="flex-shrink-0">
                                    <Controller
                                        control={control}
                                        defaultValue={false}
                                        name="privacy"
                                        inputRef={register()}
                                        render={({ field: { onChange } }) => (

                                            <Switch
                                                checked={agreed}
                                                onChange={(val) => {
                                                    setAgreed(val);
                                                    onChange(val)
                                                }}
                                                className={classNames(
                                                    agreed ? 'bg-indigo-600' : 'bg-gray-200',
                                                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                )}
                                            >
                                                <span className="sr-only">Agree to policies</span>
                                                <span
                                                    aria-hidden="true"
                                                    className={classNames(
                                                        agreed ? 'translate-x-5' : 'translate-x-0',
                                                        'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                                    )}
                                                />
                                            </Switch>
                                        )}
                                    />
                                </div>
                                <div className="ml-3">
                                    <p className="text-base text-gray-500">
                                        By selecting this, you agree to the{' '}
                                        <Link to="/privacy-policy" className="font-medium text-gray-700 underline">
                                            Privacy Policy
                                        </Link>{' '}
                                    </p>
                                </div>

                            </div>
                            {errors.privacy && <ErrorMessage message={errors.privacy.message} />}

                        </div>

                        <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} ref={reRef} />

                        <div className="col-span-2">
                            <button
                                type="submit"
                                disabled={submitting && agreed}
                                className="inline-flex items-center justify-center w-full px-6 py-3 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                {submitting ? "Applying..." : "Apply Now"}

                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
