import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import * as yup from "yup";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ErrorMessage from '../ErrorMessage';
import ReCAPTCHA from 'react-google-recaptcha';
import Cookies from 'js-cookie';
import { getCaptcha, postCareer } from '../../data/api.service';

const { yupResolver } = require("@hookform/resolvers/yup");

function CareerForm() {
    const [submitting, setSubmitting] = useState(false);
    const reRef = useRef();

    const notify = () => toast("Form submitted successfully! ");
    const error = () => toast.error("Something Went Wrong ! ");
    const captcha = () => toast.error("You must complete captcha ! ");

    const onSubmit = async (data) => {
        setSubmitting(true);
        
        const token = await reRef.current.getValue();
        if(!token) {
            captcha();
            setSubmitting(false);
            return;
        }

        const formData = new FormData();
        formData.append("firstName", data.firstName);
        formData.append("lastName", data.lastName);
        formData.append("email", data.email);
        formData.append("company", data.company);
        formData.append("phone", data.phone);
        formData.append("howWehelp", data.howWehelp);
        formData.append("lastName", data.lastName);
        formData.append("hearAbout", data.hearAbout);
        formData.append("_csrf", Cookies.get('csrf-token'));

        const response = await getCaptcha({token});
        
        response.then((res) => {
            if (res.success) {
                const response = postCareer(formData);
                response.then(() => {
                    if (response.data.success) {
                        notify();
                        setSubmitting(false);
                        reRef.current.reset();
                    } else {
                        error();
                        setSubmitting(false);
                        reRef.current.reset();
                    }
                }).catch((err) => {
                    console.log(err);
                    error();
                    setSubmitting(false);
                    reRef.current.reset();
                });
            }
        })
        // .catch((err) => {
        //     console.log(err);
        //     captcha();
        //     error();
        //     setSubmitting(false);
        //     reRef.current.reset();
        // });
    };

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    let schema = yup.object().shape({
        firstName: yup.string().required("This field is required."),
        lastName: yup.string().required("This field is required."),
        email: yup.string().email("Please enter a valid email address").required("This field is required."),
        company: yup.string().required("This field is required."),
        phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required("This field is required."),
        howWehelp: yup.string().min(10, "Your description is too short").max(500, "Your description is too long.").required("This field is required."),
        hearAbout: yup.string().min(3, "Your explanation is too short").required("This field is required."),
    });

    yupResolver(schema)
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 mt-9 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
            <div>
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                    First name 
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="firstName"
                        id="first-name"
                        autoComplete="given-name"
                        className="block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500"
                        {...register("firstName")}
                    />
                    {errors.firstName && <ErrorMessage message={errors.firstName.message} />}
                </div>
            </div>
            <div>
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                    Last name
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="lastName"
                        id="last-name"
                        autoComplete="family-name"
                        className="block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500"
                        {...register("lastName")}
                    />
                    {errors.lastName && <ErrorMessage message={errors.lastName.message} />}
                </div>
            </div>
            <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email
                </label>
                <div className="mt-1">
                    <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        className="block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500"
                        {...register("email")}
                    />
                    {errors.email && <ErrorMessage message={errors.email.message} />}
                </div>
            </div>
            <div className="sm:col-span-2">
                <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                    Company
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="company"
                        id="company"
                        autoComplete="organization"
                        className="block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500"
                        {...register("company")}
                    />
                    {errors.company && <ErrorMessage message={errors.company.message} />}
                </div>
            </div>
            <div className="sm:col-span-2">
                <div className="flex justify-between">
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                        Phone
                    </label>
                </div>
                <div className="mt-1">
                    <input
                        type="text"
                        name="phone"
                        id="phone"
                        autoComplete="tel"
                        aria-describedby="phone-description"
                        className="block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500"
                        {...register("phone")}
                    />
                    {errors.phone && <ErrorMessage message={errors.phone.message} />}
                </div>
            </div>
            <div className="sm:col-span-2">
                <div className="flex justify-between">
                    <label htmlFor="how-can-we-help" className="block text-sm font-medium text-gray-700">
                        How can we help you?
                    </label>
                    <span id="how-can-we-help-description" className="text-sm text-gray-500">
                        Max. 500 characters
                    </span>
                </div>
                <div className="mt-1">
                    <textarea
                        id="how-can-we-help"
                        name="howWehelp"
                        aria-describedby="how-can-we-help-description"
                        rows={4}
                        className="block w-full border border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500"
                        defaultValue={''}
                        {...register("howWehelp")}
                    />
                    {errors.howWehelp && <ErrorMessage message={errors.howWehelp.message} />}
                </div>
            </div>

            <div className="sm:col-span-2">
                <label htmlFor="how-did-you-hear-about-us" className="block text-sm font-medium text-gray-700">
                    How did you hear about us?
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="hearAbout"
                        id="how-did-you-hear-about-us"
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        {...register("hearAbout")}
                    />
                    {errors.hearAbout && <ErrorMessage message={errors.hearAbout.message} />}
                </div>
            </div>

            <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} ref={reRef} />

            <div className="text-right sm:col-span-2">
                <button
                    type="submit"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    disabled={submitting}
                >
                    {submitting ? "Submitting..." : "Submit"}
                </button>
            </div>
        </form>
    )
}

export default CareerForm